import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {Collapse} from '@mui/material';
import Grid from '@mui/material/Grid';
import classnames from 'classnames';

import {MHRegularButton, MHTypography} from '@/components/base';
import {ShiftDTO} from '@/types/gatewayDataModels';

import {shiftRoles} from '../../../constants';
import {convertDateTimeToUserTimezone} from '../../../utils';

import styles from './shiftItem.module.scss';

type ShiftItemProps = {
    shift: ShiftDTO;
    openEndShiftModal: (id: number) => void;
    isExpanded: boolean;
};

export const ShiftItem = ({shift, isExpanded, openEndShiftModal}: ShiftItemProps) => {
    const start = convertDateTimeToUserTimezone(shift.shiftStart);
    const end = convertDateTimeToUserTimezone(shift.shiftEnd);
    const role = shiftRoles.find(({value}) => value === shift.role)?.label;
    const personnelFullName = `${shift.personnel?.firstName} ${shift.personnel?.lastName}`;
    const clusters = shift.clusters?.map(({name}) => name).join(', ');

    const formatDateTimeForHeader = (dateTime: string) => {
        const [date, time] = dateTime.split(' ');

        return `Till ${date} at ${time}`;
    };
    return (
        <div className={styles.shiftItem}>
            <div className={styles.itemHeader}>
                <div className={styles.headerLeftSide}>
                    <ArrowRightIcon
                        className={classnames(styles.arrowIcon, {
                            [styles.fullInfoVisible]: isExpanded,
                        })}
                    />
                    <div>
                        <MHTypography variant="labelL">{role}</MHTypography>
                        <MHTypography variant="bodyS" color="black_60" noWrap maxWidth="200px">
                            {clusters}
                        </MHTypography>
                    </div>
                </div>
                <div>
                    <MHTypography variant="labelM">{personnelFullName}</MHTypography>
                    <MHTypography variant="bodyS" color="black_60">
                        {formatDateTimeForHeader(start)}
                    </MHTypography>
                </div>
            </div>
            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                <Grid container rowSpacing={1} padding="0 16px">
                    <Grid item xs={6}>
                        <MHTypography variant="labelM">Role</MHTypography>
                        <MHTypography variant="bodyS" color="black_60">
                            {role}
                        </MHTypography>
                    </Grid>
                    <Grid item xs={6}>
                        <MHTypography variant="labelM">Peronnel</MHTypography>
                        <MHTypography variant="bodyS" color="black_60">
                            {personnelFullName}
                        </MHTypography>
                    </Grid>
                    <Grid item xs={12}>
                        <MHTypography variant="labelM">Cluster</MHTypography>
                        <MHTypography variant="bodyS" color="black_60" overflowWrap="anywhere">
                            <span className={styles.clusters}>{clusters}</span>
                        </MHTypography>
                    </Grid>
                    <Grid item xs={6}>
                        <MHTypography variant="labelM">Shift Start</MHTypography>
                        <MHTypography variant="bodyS" color="black_60">
                            {start}
                        </MHTypography>
                    </Grid>
                    <Grid item xs={6}>
                        <MHTypography variant="labelM">Shift End</MHTypography>
                        <MHTypography variant="bodyS" color="black_60">
                            {end}
                        </MHTypography>
                    </Grid>
                    <Grid container margin="16px 0">
                        <MHRegularButton
                            text="End shift now"
                            btnType="secondary-positive"
                            type="submit"
                            onClick={(e) => {
                                e.stopPropagation();
                                openEndShiftModal(shift.id);
                            }}
                        />
                    </Grid>
                </Grid>
            </Collapse>
        </div>
    );
};
