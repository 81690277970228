import {StoreStateShape} from '@/redux/store/storeStateTypes';

const shiftsSelector = ({
    shiftTracker: {
        shifts,
        loadingState: {areShiftsLoading},
    },
}: StoreStateShape) => ({
    shifts,
    areShiftsLoading,
});

const addShiftModalSelector = ({
    shiftTracker: {
        isAddShiftModalActive,
        loadingState: {isShiftAddLoading},
    },
}: StoreStateShape) => ({
    isAddShiftModalActive,
    isShiftAddLoading,
});

const endShiftModalSelector = ({
    shiftTracker: {
        editedShiftId,
        loadingState: {isShiftEndLoading},
    },
}: StoreStateShape) => ({
    editedShiftId,
    isShiftEndLoading,
});

const clustersSelector = ({commonDataReducer: {clusters, clustersLoading}}: StoreStateShape) => ({
    clusters,
    clustersLoading,
});

const personnelSelector = ({shiftTracker: {personnel}}: StoreStateShape) => personnel;

export {addShiftModalSelector, clustersSelector, endShiftModalSelector, personnelSelector, shiftsSelector};
